import { Autocomplete, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import { AnswerAtom } from "../atoms";
import QuestionTitle from "./QuestionTitle";


export const DropdownQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const values = typeof value === 'string' ? value.split(',') : value;
    console.log(values);
    setAnswer(prev => ({
      ...prev,
      text: values?.join(", "),
      options: prev?.options?.map(item => ({...item, checked: values?.includes(item?.option)}))
    }));
  };

  const selectedOptions = useMemo(() => {
    return answer?.text?.split(", ")?.filter(item => item?.trim()) || []
  }, [answer?.text])

  const options = useMemo(() => {
    return answer?.options?.map(item => item?.option);
  }, [answer?.options])

  return (
    <>
      <Autocomplete
        options={options} multiple
        value={selectedOptions || []}
        onChange={(e, values) => {
          console.log(values)
          setAnswer(prev => ({
            ...prev,
            text: values?.join(", "),
            options: prev?.options?.map(item => ({...item, checked: values?.includes(item?.option)}))
          }));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // label="Multiple Autocomplete"
            // placeholder="Multiple Autocomplete"
          />
        )}
      />

      {/*<Select*/}
      {/*  // value={answer?.text?.split(", ")}*/}
      {/*  value={selectedOptions}*/}
      {/*  multiple*/}
      {/*  onChange={handleChange}*/}
      {/*>*/}
      {/*  {answer?.options?.map(item => <MenuItem key={item?.option} value={item?.option}>{item?.option}</MenuItem>)}*/}
      {/*</Select>*/}
    </>
  )
}