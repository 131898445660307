import React, {useEffect, useMemo, useRef, useState} from 'react';

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {collection, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db, functions} from "../../utils/firebaseApp";
import {Button, CssBaseline, Stack, Toolbar, Typography} from "@mui/material";

import axios from "axios";
import {ArrowBackIosNew, ChevronRight} from '@mui/icons-material';
import Box from "@mui/material/Box";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {useAtom, useAtomValue, useSetAtom} from "jotai";
import {AnswerAtom, AnswersAtom, PrefetchedImagesAtom, SurveyAtom, SurveyQuestionsAtom} from "./atoms";
import {SelectLanguage} from "./components/SelectLanguage";
import {v4 as uuid} from "uuid";
import {grey} from "@mui/material/colors";
import {Question} from "./components/Question";
import {enums} from "../../utils/enums";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";
import {uploadPhoto, uploadVoice} from "../../utils/functions";
import {httpsCallable} from "firebase/functions";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import {LinearProgressWithLabel} from "../../components/shared-components/progress/LinearProgressWithLabel";
import {VoiceRecordingState} from "../create-project-page-new/atoms";
import {QUESTION_TYPES_FOR_LOGIC} from "../../utils/enums";
import {SurveyNotAvailable} from "./components/SurveyNotAvailable";

const SurveyPage = ({isPreview = false}) => {
  const buttonRef = useRef();
  const rippleRef = useRef();
  const [survey, setSurvey] = useAtom(SurveyAtom);
  const [answers, setAnswers] = useAtom(AnswersAtom);
  const [answer, setAnswer] = useAtom(AnswerAtom);
  const [questions, setQuestions] = useAtom(SurveyQuestionsAtom);
  const setImages = useSetAtom(PrefetchedImagesAtom);
  const navigate = useNavigate()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [submitPercent, setSubmitPercent] = useState(0)
  const [submitMessage, setSubmitMessage] = useState("")
  const [ip, setIP] = useState(false)
  const [languages, setLanguages] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const isRecording = useAtomValue(VoiceRecordingState);
  const submitAnswer = httpsCallable(functions, "submitAnswer");
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        setIsKeyboardOpen(window.innerHeight > window.visualViewport.height);
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);
    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, []);

  // const searchParams = new URLSearchParams(window.location.search);
  let {uid} = useParams();
  // Extract the value of the 'token' parameter
  const token = searchParams.get('token');
  const languageBCP47 = searchParams.get('lang');

  useEffect(() => {
    if (!languageBCP47) {
      return;
    }
    let langQuestions = []
    if (languageBCP47) {
      langQuestions = questions?.filter(question => question?.languageObject?.BCP47 === languageBCP47)?.sort((a, b) => a?.orderNumber - b?.orderNumber);
    }
    const images = {};
    langQuestions?.forEach(i => {
      if (i?.imgUrl) {
        const img = new Image();
        img.src = i.imgUrl;

        img.onload = () => console.log("Preloaded image: ", i.imgUrl);
        img.onerror = (error) => console.log("Failed to preload img: ", i.imgUrl, error);
        images[i?.imgUrl] = img;
      }
    })
    setImages(images);
    setAnswers(langQuestions?.length > 0 ? [langQuestions[0]] : []);
    setAnswer(langQuestions?.length > 0 ? langQuestions[0] : null);
    setQuestionIndex(0);
  }, [questions, token, languageBCP47])

  useEffect(() => {
    console.log("ANSWERS:", answers);
    setAnswer(answers[questionIndex] || null);
  }, [answers, questionIndex])

  const addSearchParam = (key, value) => {
    // Create a new instance of searchParams to avoid mutation
    const newSearchParams = new URLSearchParams(searchParams);

    // Add or update the 'lang' param
    if (key) {
      newSearchParams.set(key, value);
      // Set the new search parameters
      setSearchParams(newSearchParams);
      console.log("search params", key, value)
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      if (isPreview) {
        toast.info("You are currently in preview survey mode.");
        return;
      }
      if (token) {
        const tokensRef = doc(db, "tokens", token);
        const tokenDoc = await getDoc(tokensRef);
        console.log(tokenDoc?.data())
        if (!tokenDoc.exists()) navigate('/404')
        if (tokenDoc.data().used) navigate('/thankyou')
      } else {
        const q = query(collection(db, "tokens"), where("surveyUid", "==", uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          toast.info("This survey is protected and can only be accessed with a valid token.")
          navigate('/404')
        }
      }
    }
    const fetchSurvey = async () => {
      try {
        setLoading(true);
        const surveyRef = doc(db, "surveys", uid);
        const surveyDocSnap = await getDoc(surveyRef);
        if (!surveyDocSnap.exists()) navigate('/404')
        const surveyDoc = surveyDocSnap.data();
        const languages = surveyDoc?.languages || []

        setSurvey(surveyDoc);
        setLanguages(languages)

        const q = query(collection(db, "questions"), where("surveyUid", "==", uid));
        const querySnapshot = await getDocs(q);
        const questionsData = []
        const respondentUid = uuid();

        // TODO set questions with respondentUid and new answer uid
        querySnapshot.forEach((doc) => {
          const question = {...doc.data()}
          questionsData.push({
            ...question,
            token: token || "",
            questionUid: question?.originalReferenceUid || question?.uid,
            uid: uuid(),
            respondentUid
          })
        });

        setQuestions(questionsData);
        setAnswers([]);
        if (!searchParams.get('lang') && languages?.length === 1) {
          addSearchParam("lang", languages[0]?.BCP47)
        }
      } catch (error) {
        setAnswers([]);
        setQuestions([]);
        setLanguages([]);
        toast.error(error?.message || "Something failed...");
        navigate("/");
      } finally {
        setLoading(false);
      }
    }

    if (uid) {
      checkToken()
      fetchSurvey();
      getIpData();
    }
  }, [uid, isPreview, token])

  async function sendAnswerObjectToBackend(answerObject) {
    console.log(answerObject)
    switch (answerObject.type) {
      case enums.IMAGE:
        const photos = [];
        for (const photoUri of answerObject?.photos || []) {
          try {
            const photo = await uploadPhoto(photoUri);
            if (photo) {
              photos.push(photo);
            }
          } catch (error) {
            console.log(error);
            toast.error("Failed to upload photo")
          }
        }
        answerObject.photos = photos
        break;
      case enums.VOICE:
        if (answerObject?.voiceBase64) {
          if (survey.saveAudioRecordings) {
            try {
              answerObject.voiceUrl = await uploadVoice(answerObject.voiceBase64)
            } catch (error) {
              console.log(error);
            }
          }
          answerObject.voiceBase64 = answerObject?.voiceBase64.substr(answerObject?.voiceBase64.indexOf(",") + 1);
        }
        break;
    }

    answerObject.ipAddress = ip
    console.log("submitting answer", answerObject)
    return submitAnswer(answerObject)
  }

  // This function animates the click effect for the button, similar to a submit button animation,
  // whenever a user selects an option from multiple choices or provides a location.
  const animateClickButton = () => {
    if (buttonRef.current && rippleRef.current) {
      const container = buttonRef.current;
      const rect = container.getBoundingClientRect();
      rippleRef.current?.start(
        {
          clientX: rect.left + rect.width / 2,
          clientY: rect.top + rect.height / 2,
        },
        // when center is true, the ripple doesn't travel to the border of the container
        {center: false},
      );
      buttonRef.current.style.transform = "scale(1.05)";

      setTimeout(() => {
        rippleRef.current?.stop({})
        buttonRef.current.style.transform = "scale(1)";
      }, 320);
    }
  }

  const handleNext = () => {
    let langQuestions = [];
    if (languageBCP47) {
      langQuestions = questions
        ?.filter(question => question?.languageObject?.BCP47 === languageBCP47)
        ?.sort((a, b) => a?.orderNumber - b?.orderNumber);
    }

    const otherCaseQuestion = langQuestions?.find(i => i?.orderNumber === answer?.allOtherCasesQuestion);
    let nextQuestionUid = otherCaseQuestion?.questionUid;
    console.log("OTHER CASES", otherCaseQuestion, nextQuestionUid)
    if (QUESTION_TYPES_FOR_LOGIC.includes(answer?.type) && answer?.rules?.length > 0) {
      let selectedOption;

      // Find the selected option based on question type
      if (answer.type === enums.MULTICHOICE || answer.type === enums.DROPDOWN) {
        selectedOption = answer.options.find(opt => opt.checked);
      } else if (answer.type === enums.CHECKBOXES) {
        selectedOption = answer.options.find(opt => opt.checked);
      }

      if (selectedOption) {
        const matchingRule = answer.rules.find(rule => rule.answerOption === selectedOption.option);
        if (matchingRule?.nextQuestion) {
          const nextQuestion = langQuestions.find(q => q.questionUid === matchingRule.nextQuestion);
          nextQuestionUid = nextQuestion?.questionUid || nextQuestionUid;
        }
      }
    }

    // Find the current answer index
    const previousAnswer = answers[questionIndex];

    if (
      questionIndex < answers.length - 1 &&
      previousAnswer?.text === answer?.text
    ) {
      // If this is not the last question and answer.text didn't change, go to the next question by index
      // setAnswer(answers[questionIndex + 1]);
      setQuestionIndex(prevIndex => prevIndex + 1);
      console.log("GO TO NEXT", previousAnswer, answer)
    } else if (
      previousAnswer?.text !== answer?.text ||
      questionIndex === answers.length - 1
    ) {
      // If the answer has changed or this is the last answer, move to nextQuestionUid if available
      const nextQuestion = langQuestions.find(item => item?.questionUid === nextQuestionUid);
      console.log("NEXT", nextQuestion)
      if (nextQuestion) {
        const updatedAnswers = [...answers.slice(0, questionIndex), answer, nextQuestion]; // Keep answers up to current
        setAnswers(updatedAnswers); // Update answers list
        // setAnswer(nextQuestion);
        setQuestionIndex(prevIndex => prevIndex + 1);
      }
    }
  };

  const handleSubmit = async (delay = 0) => {
    saveAnswer();
    animateClickButton();

    console.log("CLICKED", answer)
    if (delay > 0) {
      await new Promise(resolve => setTimeout(resolve, delay));
    }

    if (!!answer?.allOtherCasesQuestion) {
      // TODO IF THERE IS SOME NEXT QUESTION THEN GO TO NEXT
      handleNext();
      return;
    } else {
      const response = await confirm({
        title: `Are you sure you want to submit your answers?`
      }, confirmDialogOptions);
      if (response) {
        setAnswer(null);
        if (isPreview) {
          toast.info("Since this is a preview survey, your submitted answers will not be saved in the database.");
          navigate(`/create-survey/${survey?.uid}`);
          return;
        }
        const timestamp = new Date().getTime()
        const submittingAnswers = [...answers]?.map(answer => ({...answer, timestamp: timestamp}));
        if (submittingAnswers[questionIndex]) {
          submittingAnswers[questionIndex] = {...answer, timestamp: timestamp, submitted: true};
        }
        setLoading(true);
        let error = false;
        let index = 0;
        for (const submittingAnswer of submittingAnswers) {
          try {
            setSubmitMessage("Submitting your answers...")
            index = index + 1;
            setSubmitPercent(((index / (submittingAnswers?.length)) * 100) || 0);
            await sendAnswerObjectToBackend(submittingAnswer);
          } catch (e) {
            console.log(e);
            error = true;
            setSubmitMessage(`Failed to submit answer "${submittingAnswer?.question}"`)

          }
        }
        setSubmitMessage("Survey submission completed successfully.")

        if (token) {
          try {
            const tokensRef = doc(db, "tokens", token);
            await updateDoc(tokensRef, {used: true});
          } catch (error) {
            console.log(error);
          }
        }
        navigate("/thankyou", {replace: true})
        setLoading(false);
        setSubmitPercent(0);
        setSubmitMessage("");
      }
      return;
    }
  }

  const onSubmit = async (e) => {
    e?.preventDefault();
    handleSubmit();
  }

  const saveAnswer = () => {
    if (answer && answers[questionIndex]) {
      setAnswers(prev => {
        const state = [...prev];
        state[questionIndex] = {...answer, submitted: true};
        return state;
      })
    }
  }

  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    try {
      const ipDetails = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=ac9c0f52f42c4ad18160a3329030eef2&ip=${res.data.ip}&fields=geo`)
      setIP(ipDetails.data)
    } catch (error) {
      console.log(error);
    }
  };

  const percent = useMemo(() => {
    let val = 0;
    if (questionIndex + 1 > 0 && answers?.length > 0) {
      val = ((questionIndex + 1) / (answers?.length)) * 100;
    }
    return val;
  }, [questionIndex, answers.length])

  const canSubmit = useMemo(() => {
    if (!answer?.required) {
      return true;
    }
    let isValid = true;
    switch (answer?.type) {
      case enums.IMAGE:
        console.log("image is valid:", !!answer?.photos && answer?.photos?.length > 0 && answer?.photos?.length <= 3)
        isValid = !!answer?.photos && answer?.photos?.length > 0 && answer?.photos?.length <= 3;
        break;
      case enums.TEXT:
        isValid = !!answer?.text;
        break;
      case enums.SLIDER:
        isValid = !!answer?.text;
        break;
      case enums.VOICE:
        isValid = !!answer?.text || (!!answer?.voiceBase64 && answer?.audioDuration && answer?.audioDuration > 0 && answer?.audioDuration < 60);
        break;
      case enums.MULTICHOICE:
        isValid = !!answer?.text && answer?.options?.filter(i => i?.checked)?.length > 0;
        break;
      case enums.CHECKBOXES:
        isValid = !!answer?.text && answer?.options?.filter(i => i?.checked)?.length > 0;
        break;
      case enums.DROPDOWN:
        isValid = !!answer?.text && answer?.options?.filter(i => i?.checked)?.length > 0;
        break;
      case enums.MAPINPUT:
        isValid = answer?.pois && answer?.pois?.length > 0;
        break;
      case enums.SECTION:
        isValid = true;
        break;
      case enums.LOCATE_USER:
        isValid = !!answer?.text && !!answer?.lng && !!answer?.lat;
        break;
      case enums.IMAGE_GALLERY:
        isValid = !!answer?.text && !!answer?.options?.filter(i => i?.checked)?.length > 0;
        break;
      default:
        isValid = true;
        break;
    }

    return isValid;
  }, [answer])

  const isDateValid = useMemo(() => {
    let isValid = true;
    const dateNow = new Date().getTime();
    if (survey?.start) {
      isValid = dateNow > survey?.start;
    } else if (survey?.end && isValid) {
      isValid = dateNow < survey?.end;
    }
    return isValid;
  }, [survey]);


  return (
    isDateValid ? <Stack
        direction={"column"}
        justifyContent={'space-between'}
        component={"form"}
        onSubmit={onSubmit}
        sx={{
          maxWidth: 'md',
          width: "100%",
          height: "100%",
          px: 2, py: 4,
          mx: 'auto',
        }}>
        <CssBaseline/>
        <SelectLanguage
          languages={languages}
          selectedLanguage={languageBCP47}
          onSelect={(lang) => addSearchParam("lang", lang)}
        />

        {loading &&
          <Box sx={{
            position: "absolute",
            inset: 0,
            bgcolor: "background.paper",
            zIndex: 1000
          }}>
            <LoadingScreen/>
            {submitPercent > 0 ? (
              <Box maxWidth={"sm"} sx={{mx: 'auto', width: "90%"}}>
                <LinearProgressWithLabel
                  value={submitPercent}
                />
                <Typography align={"center"} color={"#858585"} fontWeight={500}
                            variant={"h5"}>{submitMessage}</Typography>
              </Box>
            ) : null}
          </Box>}

        {languageBCP47 && <Question/>}
        <Toolbar/>
        <Toolbar
          sx={{position: "fixed", bottom: 0, top: isKeyboardOpen ? "calc(50% - 100px)" : undefined, left: 0, right: 0, maxWidth: "md", mx: "auto", bgcolor: "background.paper"}}>
          <Stack direction={"row"} maxWidth={"sm"}
                 sx={{width: "100%", pb: 4, mx: 'auto', mb: "auto", pt: 0.5, px: 2, bgcolor: "background.paper"}}
                 justifyContent={"space-between"}
                 alignContent={"center"}
                 gap={2}>
            <Button variant={"contained"}
                    disabled={questionIndex <= 0 || isRecording}
                    sx={{
                      bgcolor: grey[300],
                      borderRadius: 2,
                      boxShadow: 0,
                      p: "12px 20px",
                      '&:hover': {bgcolor: grey[200], boxShadow: 0}
                    }}
                    onClick={() => {
                      saveAnswer();
                      setQuestionIndex(questionIndex - 1);
                    }}>
              <ArrowBackIosNew/>
            </Button>
            <Button
              type={"submit"} className={"gradient-btn"}
              endIcon={<ChevronRight/>}
              sx={{flex: 1, p: "12px 20px", transition: "transform 0.2s ease-in-out", display: "relative"}}
              disabled={!canSubmit || loading || isRecording}
              disableRipple
              ref={buttonRef}
            >
              {!!answer?.allOtherCasesQuestion ? "Continue" : "Submit"}
              <TouchRipple ref={rippleRef} center/>
            </Button>
          </Stack>
        </Toolbar>
      </Stack> :
      <SurveyNotAvailable/>
  );
}


export default SurveyPage;

