import React, { useState, useContext, useRef } from "react";
import
  {
    Box,
    Button,
    CssBaseline,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Stack,
    Toolbar,
    Tooltip,
  } from "@mui/material";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { auth } from "../../utils/firebaseApp";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { Header } from "../../components/main-header/HeaderElla";
import
  {
    IconAppWindow,
    IconCode,
    IconLogout,
    IconFolder,
    IconSparkles,
    IconCategory2,
    IconReport,
    IconMessage2,
    IconUser,
    IconChartPie
  } from "@tabler/icons-react"
import logoImg from "../../assets/logo/Ella.svg";
import { WorkspacesPopover } from './WorkspacesPopover';
import { AppContext } from '../../utils/AppContext';
import { enums } from "../../utils/enums";
import { SurveySettingsModal } from "../../pages/create-project-page-new/components/survey-settings-modal";
import { CreateQuestionModal } from "../../pages/create-project-page-new/components/create-question-modal";
import { useAtomValue } from "jotai";
import { AiDrawerState } from "../../pages/create-project-page-new/atoms";
import { AiDrawer } from "../../pages/create-project-page-new/components/AiDrawer";
import { FreeTierBanner } from "./components/FreeTierBanner";

export const drawerWidth = 280;

const navLinks = [
  { path: "/dashboard", name: "Dashboard", startIcon: <IconAppWindow /> },
  { path: "/surveys", name: "My surveys", startIcon: <IconCategory2 /> },
  { path: "/summaries", name: "Summaries", startIcon: <IconChartPie /> },
  { path: "/reports", name: "Reports", startIcon: <IconReport /> },
  { path: "/analysis", name: "Responses", startIcon: <IconMessage2 /> },
  { path: "/survey-designer", name: "AI Survey Designer", startIcon: <IconSparkles /> },
  { path: "/api", name: "Advanced Access", startIcon: <IconCode /> },
  { path: "/settings", name: "Profile", startIcon: <IconUser /> },
];

export const MainLayout = () =>
{
  const parentRef = useRef(null);
  const aiDrawerOpen = useAtomValue(AiDrawerState);

  const [isOpen, setIsOpen] = useState(false);
  const [workspacesAnchor, setWorkspacesAnchor] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100dvh",
        overflowX: "hidden",
      }}
    >
      <CssBaseline />
      <Header toggleMenu={setIsOpen} open={isOpen} />
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} setWorkspacesAnchor={setWorkspacesAnchor} />
      <Stack
        direction={"column"}
        component="main"
        sx={{
          flexGrow: 1,
          pl: { xs: 0, md: "56px" },
          pr: { xs: 0 },
          minHeight: "100%",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <Toolbar />
        <FreeTierBanner />

        <Box
          sx={{
            minHeight: "calc(100dvh - 80px)", height: "100%",
            position: "relative",
            p: { xs: 0, md: 3 }, overflowY: "auto",
            maxWidth: aiDrawerOpen ? "calc(100% - 400px)" : "100%",
            width: "100%"
            // mr: aiDrawerOpen ? "400px" : "0",
          }}
          id={"box-with-children"}
          ref={parentRef}
        >
          <Outlet />
          <SurveySettingsModal container={parentRef?.current} />
          <CreateQuestionModal container={parentRef?.current} />
          <AiDrawer />
        </Box>
      </Stack>
      <WorkspacesPopover
        anchorEl={workspacesAnchor}
        onClose={() => setWorkspacesAnchor(null)}
      />
    </Box>
  );
};

const Sidebar = ({ isOpen, setIsOpen, setWorkspacesAnchor }) =>
{
  const navigate = useNavigate();

  const logout = () =>
  {
    signOut(auth)
      .then(() =>
      {
        navigate("/login");
      })
      .catch((error) =>
      {
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <Stack
        direction={"column"} gap={0}
        className={`sidebar`}
        sx={{
          position: "fixed",
          bgcolor: "background.paper",
          zIndex: 998,
          maxWidth: `${drawerWidth}px`,
          height: '100dvh',
          display: { xs: "none", md: "flex" }
        }}
      >
        <DrawerItems logoutHandle={logout} handleClose={() => setIsOpen(false)}
          setWorkspacesAnchor={setWorkspacesAnchor} />
      </Stack>
      <Drawer
        variant={"temporary"}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        className={"sidebar active"}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          border: 0,
          height: "100%",
          display: { xs: "flex", md: "none !important" },
          flexDirection: "column", gap: 3,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            border: 0,
          },
        }}
      >
        <DrawerItems logoutHandle={logout} handleClose={() => setIsOpen(false)}
          setWorkspacesAnchor={setWorkspacesAnchor} />
      </Drawer>
    </>
  )
}

const DrawerItems = ({ logoutHandle, handleClose, setWorkspacesAnchor }) =>
{
  const { state } = useContext(AppContext);

  // Find current workspace from state
  const currentWorkspace = state.workspaces?.find(w => w.uid === state.workspaceId);

  return (
    <>
      <Toolbar>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ display: { xs: "flex", md: 'none' } }}
        >
          <img src={logoImg} alt={"Logo"} height={60} />
        </Stack>
      </Toolbar>
      <Box sx={{ mx: 2, mt: 3, display: { xs: "inline-flex", md: "none" } }}>
        <Button
          component={Link}
          to={"/create-survey"}
          fullWidth
          sx={{ p: 1 }}
          variant={"contained"}
          className={"gradient-btn"}
          startIcon={<AddIcon />}
          disabled={currentWorkspace?.plan === enums.UNPAID}
        >
          Create Survey
        </Button>
      </Box>
      <List sx={{ border: 0, flex: 1 }}>
        {navLinks.map((item, index) => (
          <ListItemButton key={item?.path}
            className={"sidebar-item"}
            component={NavLink} to={item.path}
            onClick={handleClose}
            sx={{
              px: 2,
              color: "secondary.dark",
              "&.active": { color: "#5271FF !important", fontWeight: 600 }
            }}
          >
            {item.startIcon ? <ListItemIcon
              sx={{ minWidth: 0, pr: 1, color: "inherit" }}>{item?.startIcon}</ListItemIcon> : null}
            <ListItemText
              className={"sidebar-item-text"}
              primaryTypographyProps={{
                sx: {
                  fontWeight: "inherit",
                  color: "inherit",
                  padding: "0px",
                  fontSize: "1.02em",
                  width: 'auto',
                  textWrap: "nowrap",
                  wordWrap: "nowrap",
                },
              }}
              primary={item.name}
            />
            {item.endIcon ? <ListItemIcon sx={{ minWidth: 'auto', color: "inherit" }}>
              <Tooltip title={'Experimental feature'}>
                {item?.endIcon}
              </Tooltip>
            </ListItemIcon> : null}
          </ListItemButton>
        ))}
      </List>
      <List>
        <ListItemButton
          className={"sidebar-item"}
          sx={{ mt: "auto !important", px: 2, color: "secondary.dark" }}
          onClick={(event) => setWorkspacesAnchor(event.currentTarget)}
        >
          <ListItemIcon sx={{ color: "secondary.dark" }}>
            <IconFolder />
          </ListItemIcon>
          <ListItemText
            className={"sidebar-item-text"}
            primaryTypographyProps={{
              sx: {
                color: "secondary.dark",
                padding: "0px",
                fontSize: "1.02em",
                width: 'auto',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              },
            }}
            primary={currentWorkspace?.name || "Select Workspace"}
          />
        </ListItemButton>
        <ListItemButton className={"sidebar-item"} sx={{ mt: "auto !important", px: 2, color: "secondary.dark" }}
          onClick={logoutHandle}>
          <ListItemIcon sx={{ color: "secondary.dark" }}><IconLogout /></ListItemIcon>
          <ListItemText
            className={"sidebar-item-text"}
            primaryTypographyProps={{
              sx: {
                color: "secondary.dark",
                padding: "0px",
                fontSize: "1.02em",
                width: 'auto',
              },
            }}
            primary={"Logout"}
          />
        </ListItemButton>
      </List>
    </>
  );
};

