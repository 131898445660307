import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import React from "react";
import {IconButton, InputAdornment, Slider, Stack, TextField, Typography, Box, ButtonGroup} from "@mui/material";
import {IconChevronDown, IconChevronUp} from "@tabler/icons-react";


const sliderStyle = {
  color: '#60abce', borderRadius: 2,
  height: 12, py: '0 !important',
  justifySelf: 'stretch',
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },

}

export const SliderQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  return (
    <>
      <Box sx={{width: {xs: "85%", md: "100%"}, mx: "auto"}}>
        <Slider
          value={answer?.text || answer?.from || 0}
          sx={sliderStyle}
          min={answer?.from ? +answer?.from : 0}
          max={answer?.to ? +answer?.to : 100}
          onChange={(e) => setAnswer(prev => ({...prev, text: +e?.target?.value}))}
          aria-labelledby="input-slider"
        />
        <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"} sx={{pt: 0.5}}>
          <Typography fontSize={"small"}>Min: {answer?.from}</Typography>

          <Typography fontSize={"small"}>Max: {answer?.to}</Typography>
        </Stack>
      </Box>

      <Box>
        <TextField
          type="number"
          size={"medium"}
          value={answer?.text || answer?.from || ""}
          onChange={(e) => setAnswer(prev => ({...prev, text: +e?.target?.value}))}
          sx={{
            minWidth: "150px", width: "150px",
            '& .MuiInputBase-root': {p: '3px !important'}
          }}
          error={answer?.text < answer?.from || answer?.text > answer?.to}
          helperText={(answer?.text < answer?.from || answer?.text > answer?.to) && "value must be between " + answer?.from + " and " + answer?.to}
          InputProps={{
            min: answer?.from ? +answer?.from : 0,
            max: answer?.to ? +answer?.to : 100,
            endAdornment: (
              <InputAdornment position="end">

                <ButtonGroup
                  orientation="vertical"
                  aria-label="Vertical button group"
                  variant="contained" size={"small"}
                  sx={{bgcolor: "transparent", boxShadow: 0}}
                >
                  <IconButton size={"small"} onClick={() => {
                    const maxValue = +answer?.to || 100;
                    const step = Math.ceil(maxValue / 10);

                    setAnswer(prev => ({...prev, text: +prev?.text + step > maxValue ? maxValue : +answer?.text + step}));
                  }}>
                    <IconChevronUp size={17}/>
                  </IconButton>
                  <IconButton size={"small"}  onClick={() => {
                    const minValue = +answer?.from || 0;
                    const maxValue = +answer?.to || 100;
                    const step = Math.ceil(maxValue / 10);

                    setAnswer(prev => ({...prev, text: +prev?.text - step < minValue ? minValue : +answer?.text - step}));
                  }}>
                    <IconChevronDown size={17}/>
                  </IconButton>
                </ButtonGroup>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </>
  )
}