import { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Add, DeleteOutline, DragIndicator, AddPhotoAlternate } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { grey } from "@mui/material/colors";
import { QuestionTypes } from "../../enums";
import { toast } from "react-toastify";

export const OptionsQuestions = ({ question, onUpdate }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!question?.options || question?.options.length === 0) {
      onUpdate("options", [{ option: "", checked: false }]);
    }
  }, [question]);

  const onUpdateOption = (index, value) => {
    const options = question?.options ? [...question?.options] : [];
    
    // Check for duplicates
    const isDuplicate = options.some((option, i) => 
      i !== index && 
      option.option.trim().toLowerCase() === value.trim().toLowerCase() &&
      option.option.length === value.length
    );
    
    // Update error state
    if (isDuplicate) {
      setErrors(prev => ({
        ...prev,
        [index]: "An option with this text already exists"
      }));
    } else {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[index];
        return newErrors;
      });
    }

    // Update the option text
    if (index < options.length) {
      options[index].option = value;
      onUpdate("options", options);
    }
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const options = [...question.options];
      options[index] = {
        ...options[index],
        imageUrl: URL.createObjectURL(file)
      };
      onUpdate("options", options);
    }
  };

  const onRemoveOption = (index) => {
    let options = question?.options ? [...question?.options] : [];
    if (index < options.length) {
      options = options?.filter((_, ind) => ind !== index);
      onUpdate("options", options);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(question.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onUpdate("options", items);
  };

  const isOtherPresent = () => {
    return question?.options?.some(option => option.other);
  }

  return (
    <>
      {question?.type === QuestionTypes.IMAGE_GALLERY && (
        <Box sx={{ mb: 2 }}>
          <Typography fontSize={"0.75rem"} color={grey[500]} fontWeight={300}>
            Click the camera icon next to each option to upload an image. We recommend images {"<100kb"} so your survey loads fast. You can optimize your images on sites like
            {" "}
            <a href={"https://imagecompressor.com/"} target={"_blank"} style={{ color: "inherit" }}>Optimizilla</a>
            {" "}or{" "}
            <a href={"https://squoosh.app/"} target={"_blank"} style={{ color: "inherit" }}>Squoosh</a>.
          </Typography>
        </Box>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="options">
          {(provided) => (
            <Stack
              direction={"column"}
              gap={1}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {question?.options?.map((optionItem, index) => {
                return (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided) => (
                      <Stack
                        key={index}
                        direction={"column"}
                        gap={1}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Stack direction="row" gap={2} alignItems="center">
                          <Box {...provided.dragHandleProps}>
                            <DragIndicator sx={{ cursor: "grab" }} />
                          </Box>

                          <Box sx={{ flex: 1 }}>
                            <TextField
                              variant={"standard"}
                              disabled={optionItem?.other}
                              placeholder={optionItem?.other ? "Other" : `Enter option ${index + 1}`}
                              value={optionItem?.option}
                              autoFocus
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && e?.target?.value) {
                                  e.preventDefault();
                                  e.target.blur();
                                  onUpdate("options", [...question?.options, { option: "", checked: false }]);
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                const pastedText = e.clipboardData.getData("text");
                                if (pastedText.includes("\n")) {
                                  const newOptions = pastedText.split("\n").filter((text) => text.trim() !== "").map((text) => ({ option: text, checked: false }));

                                  if (newOptions.length > 0) {
                                    let options = [...question.options];

                                    // Replace current option with the first pasted row
                                    options[index] = newOptions[0];

                                    // Insert remaining new options after the current index
                                    options.splice(index + 1, 0, ...newOptions.slice(1));

                                    onUpdate("options", options);
                                  }
                                } else {
                                  onUpdateOption(index, pastedText);
                                }
                              }}
                              onChange={(e) => onUpdateOption(index, e?.target?.value)}
                              sx={{ width: '100%' }}
                              error={!!errors[index]}
                            />
                            {errors[index] && (
                              <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
                                {errors[index]}
                              </Typography>
                            )}
                          </Box>

                          {question.type === QuestionTypes.IMAGE_GALLERY && optionItem.imageUrl && (
                            <Box sx={{ mb: 0, display: 'flex', justifyContent: 'center' }}>
                              <img
                                src={optionItem.imageUrl}
                                alt={optionItem.option}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  objectFit: "cover",
                                  borderRadius: "4px"
                                }}
                              />
                            </Box>
                          )}
                          {question.type === QuestionTypes.IMAGE_GALLERY && (
                            <IconButton component="label">
                              <AddPhotoAlternate />
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageUpload(e, index)}
                              />
                            </IconButton>
                          )}
                          <IconButton onClick={() => onRemoveOption(index)}>
                            <DeleteOutline />
                          </IconButton>
                        </Stack>
                      </Stack>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              {question?.options?.length >= 6 && (
                <Typography variant={"body2"}>
                  Heads up, adding too many options can be overwhelming to respondents!
                </Typography>
              )}
              <Stack direction={"row"} gap={1} sx={{ textAlign: "center" }}>
                <Button
                  startIcon={<Add />}
                  className={"gradient-outlined-btn"}
                  variant={"outlined"}
                  sx={{textTransform: "none !important"}}
                  onClick={() => onUpdate("options", [...question?.options, { option: "", checked: false }])}
                >
                  Add Option 
                </Button>
                <Button
                  startIcon={<Add />}
                  className={"gradient-outlined-btn"}
                  variant={"outlined"}
                  sx={{textTransform: "none !important", color: isOtherPresent() && "gray !important"}}
                  disabled={isOtherPresent()}
                  onClick={() => onUpdate("options", [...question?.options, { option: "", checked: false, other: true }])}
                >
                  Add Other 
                </Button>
              </Stack>
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
