import { FormControl, FormControlLabel, RadioGroup, Stack, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { AnswerAtom } from "../atoms";


export const MultiChoiceQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  const onTextChange = (index, text) => {
    setAnswer(prev => {
      const options = [...(prev?.options || [])];
      console.log(index, text, options)
      if (options?.length >= index) {
        options[index].option = text;
      }
      return {
        ...prev,
        options: [...options]
      }
    });
  };

  const onSelect = (index, text) => {
    setAnswer(prev => ({
      ...prev,
      text: text,
      options: prev?.options?.map((i, ind) => ({
        ...i,
        checked: ind === index,
        option: i?.other && ind !== index ? "" : i?.option,
      }))
    }));
  };

  return (
    <>
      <FormControl>
        <RadioGroup>
          {answer?.options?.map((item, index) => {
            if (item?.other) {
              return (
                <FormControlLabel
                  key="other"
                  value="other"
                  control={
                    <Stack direction="row" spacing={1} alignItems="flex-start">
                      <Radio
                        checked={item?.checked}
                        disabled={!item?.option}
                        onChange={() => onSelect(index, item?.option)}
                      />
                      <TextField
                        size="small"
                        value={item?.option}
                        placeholder="Other"
                        onChange={(e) => onTextChange(index, e?.target?.value)}
                        onBlur={e => {
                          setAnswer(prev => {
                            let options = [...(prev?.options || [])];
                            if (options?.length >= index) {
                              options[index].checked = !!options[index].option;
                              if (options[index]?.checked) {
                                options = options?.map((i, idx) => idx !== index && i?.checked ? {...i, checked: false} : {...i})
                              }
                            }
                            return {
                              ...prev,
                              text: options[index].option,
                              options: [...options]
                            }
                          });
                        }}
                        // disabled={!item?.checked}
                      />
                    </Stack>
                  }
                  label=""
                />
              );
            }

            return (
              <FormControlLabel
                value={item?.option}
                control={<Radio checked={item?.checked} />}
                checked={item?.checked}
                label={item?.option}
                key={item.option}
                sx={{ pb: 0.5 }}
                onChange={(e) => onSelect(index, item?.option)}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};