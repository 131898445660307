import { Button, Paper, Typography } from '@mui/material';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  Handle,
  MiniMap,
  Position,
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useReactFlow
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useAtomValue } from "jotai";
import React, { useCallback, useMemo, useState, useEffect, useReducer } from 'react';
import { QUESTION_TYPES_FOR_LOGIC } from '../../../../utils/enums';
import { CreateSurveyQuestionsState } from "../../atoms";
import { LogicModal } from './rules-modal';
import Dagre from '@dagrejs/dagre';

const QuestionNode = ({ data, isConnectable }) => {
  const [, forceUpdate] = useReducer(forceUpdateReducer, 0);

  const [modalOpen, setModalOpen] = useState(false);
  const question = data?.question;
  const questions = useAtomValue(CreateSurveyQuestionsState).filter(q => q.originalReferenceUid === null);

  const isApplicableType = (questionType) => {
    return QUESTION_TYPES_FOR_LOGIC.includes(questionType);
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{
          padding: '20px',
          margin: '10px 0',
          borderRadius: '10px',
          minHeight: '100px',  // Minimum height to ensure visibility
          width: '360px',
          position: 'relative',  // For absolute positioning of button
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Handle
          type="target"
          position={Position.Left}
          style={{ top: '50%' }}
          isConnectable={isConnectable}
        />
        <Typography
          variant="h6"
          style={{
            fontWeight: 'semibold',
            marginBottom: '40px',  // Space for button
            flex: '1'
          }}
        >
          {question?.orderNumber + 1}. {question?.question}
        </Typography>
        <Handle
          type="source"
          position={Position.Right}
          style={{ top: '50%' }}
          isConnectable={isConnectable}
        />
        <Button
          variant="contained"
          onClick={() => setModalOpen(true)}
          className="nodrag"
          sx={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
          }}
          size="small"
          disabled={!isApplicableType(question?.type)}
        >
          Edit logic
        </Button>
      </Paper>
      {modalOpen && <LogicModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        question={question}
        questions={questions}
        forceUpdate={forceUpdate}
      />}
    </>
  );
};

// Define node dimensions as constants at the top
const NODE_WIDTH = 360;  // Width of our Paper component
const NODE_HEIGHT = 100; // Minimum height we set
const NODE_PADDING = 50; // Minimum space between nodes

const getLayoutedElements = (nodes, edges, options) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  // Add more spacing between ranks (vertical layers)
  g.setGraph({
    rankdir: options.direction,
    ranksep: 150,  // Increase vertical separation between ranks
    nodesep: 100,  // Horizontal separation between nodes
    edgesep: 80,   // Minimum separation between edges
    rankdir: 'LR'  // Left to Right layout
  });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) => {
    g.setNode(node.id, {
      width: NODE_WIDTH,
      height: NODE_HEIGHT
    });
  });

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      return {
        ...node,
        position: {
          x: position.x - NODE_WIDTH / 2,
          y: position.y - NODE_HEIGHT / 2
        }
      };
    }),
    edges
  };
};

// Move nodeTypes outside the component
const nodeTypes = { questionNode: QuestionNode };
const forceUpdateReducer = (x) => x + 1;

const SurveyLogicFlow = () => {
  const questions = useAtomValue(CreateSurveyQuestionsState).filter(q => q.originalReferenceUid === null);
  const reactFlowInstance = useReactFlow();
  const [questionsLocal, setQuestionsLocal] = useState(questions)
  const [rerenderKey, setRerenderKey] = useState(0)
  // Define all constants at the top
  const horizontalSpacing = 400;
  const verticalOffset = 150;
  const baseY = 100;


  const calculateNodePositions = useMemo(() => {
    const positions = {};

    // Track outgoing and incoming connections
    const outgoingConnections = new Map(); // source uid -> count of total rules (including default next)
    const incomingConnections = new Map(); // target uid -> count of times targeted by rules

    // First pass: Count all connections
    questions.forEach((question) => {
      // Count outgoing connections (rules + default next)
      let outgoingCount = 1; // Start with 1 for the default next question
      if (question.rules) {
        outgoingCount += question.rules.length;
        // Count incoming connections for rule targets
        question.rules.forEach(rule => {
          if (rule.nextQuestion) {
            incomingConnections.set(
              rule.nextQuestion,
              (incomingConnections.get(rule.nextQuestion) || 0) + 1
            );
          }
        });
      }
      outgoingConnections.set(question.uid, outgoingCount);
    });
    console.log("outgoingConnections", outgoingConnections)
    console.log("incomingConnections", incomingConnections)
    // Position nodes
    questions.forEach((question) => {
      const hasMultipleOutgoing = (outgoingConnections.get(question.uid) || 0) > 1;
      const incomingCount = incomingConnections.get(question.uid) || 0;

      positions[question.orderNumber] = {
        x: question.orderNumber * horizontalSpacing,
        y: hasMultipleOutgoing ? baseY + verticalOffset :
          incomingCount > 0 ? baseY + (verticalOffset * incomingCount) : baseY
      };
    });

    return positions;
  }, [questions]);

  const initialNodes = useMemo(() => questions.map((question) => ({
    id: question.orderNumber.toString(),
    type: 'questionNode',
    position: calculateNodePositions[question.orderNumber] || {
      x: question.orderNumber * horizontalSpacing,
      y: baseY
    },
    data: {
      orderNumber: question.orderNumber,
      question: question,
      allQuestions: questions
    },
  })), [questions]);

  // Create initial edges
  const initialEdges = useMemo(() => {
    const edgesList = [];

    questions.forEach((question, index) => {
      const hasRules = question.rules && question.rules.length > 0;

      // Add rule-based edges first
      if (hasRules) {
        question.rules.forEach((rule) => {
          if (rule.nextQuestion) {
            const targetQuestion = questions.find(q => q.uid === rule.nextQuestion);
            if (targetQuestion) {
              edgesList.push({
                id: `e${question.orderNumber}-rule-${rule.uid}`,
                source: question.orderNumber.toString(),
                target: targetQuestion.orderNumber.toString(),
                type: 'straight',
                animated: true,
                style: {
                  strokeWidth: 2,
                  stroke: '#2196f3',
                },
                markerEnd: {
                  type: 'arrow',
                  width: 10,
                  height: 10,
                  color: '#2196f3',
                },
                label: rule.answerOption,
                pathOptions: {
                  offset: 25,
                  borderRadius: 20
                }
              });
            }
          }
        });
      }

      // Add "all other cases" edge
      if (question.allOtherCasesQuestion) {
        const targetQuestion = questions.find(q => q.orderNumber === question.allOtherCasesQuestion);
        if (targetQuestion) {
          edgesList.push({
            id: `e${question.orderNumber}-other`,
            source: question.orderNumber.toString(),
            target: targetQuestion.orderNumber.toString(),
            type: 'straight',
            animated: true,
            style: {
              strokeWidth: 2,
              stroke: '#888',
              strokeDasharray: '5,5',
            },
            markerEnd: {
              type: 'arrow',
              width: 10,
              height: 10,
              color: '#888',
            },
            label: 'All other cases',
            pathOptions: {
              offset: 25,
              borderRadius: 20
            }
          });
        }
      }
      // Default sequential edge - Fixed condition here
      else if (!hasRules && question.orderNumber < questions.length - 1) {  // Changed from index to question.orderNumber
        edgesList.push({
          id: `e${question.orderNumber}-${question.orderNumber + 1}`,
          source: question.orderNumber.toString(),
          target: (question.orderNumber + 1).toString(),
          type: 'straight',
          animated: true,
          style: {
            strokeWidth: 2,
            stroke: '#888',
          },
          markerEnd: {
            type: 'arrow',
            width: 10,
            height: 10,
            color: '#888',
          },
          pathOptions: {
            offset: 25,
            borderRadius: 20
          }
        });
      }
    });

    return edgesList;
  }, [questions]);

  // State management following React Flow's pattern
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);


  const { fitView } = useReactFlow();

  // Apply horizontal layout and zoom out on initial render
  useEffect(() => {
    const layouted = getLayoutedElements(nodes, edges, { direction: 'LR' });
    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);

    window.requestAnimationFrame(() => {
      fitView({
        padding: 200,
        minZoom: 0.1,
        maxZoom: 1.5,
        duration: 200
      });
    });
  }, [nodes.length, edges.length]); // Only re-layout when nodes or edges change


  const onNodeDrag = useCallback((event, node, nodes) => {
    const otherNodes = nodes.filter(n => n.id !== node.id);

    otherNodes.forEach(otherNode => {
      const horizontalOverlap =
        node.position.x < otherNode.position.x + NODE_WIDTH + NODE_PADDING &&
        node.position.x + NODE_WIDTH + NODE_PADDING > otherNode.position.x;

      const verticalOverlap =
        node.position.y < otherNode.position.y + NODE_HEIGHT + NODE_PADDING &&
        node.position.y + NODE_HEIGHT + NODE_PADDING > otherNode.position.y;

      if (horizontalOverlap && verticalOverlap) {
        setNodes(nds =>
          nds.map(n => {
            if (n.id === otherNode.id) {
              return {
                ...n,
                position: {
                  x: n.position.x,
                  y: node.position.y + NODE_HEIGHT + NODE_PADDING
                }
              };
            }
            return n;
          })
        );
      }
    });
  }, []);

  // Node changes handler
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  // Edge changes handler
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  // Connection handler
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeDrag={onNodeDrag}
        nodeTypes={nodeTypes}
        style={{ backgroundColor: '#f5f5f5', borderRadius: '10px' }}
        connectOnClick={false}
        nodesConnectable={false}
        elementsSelectable={true}
        nodesDraggable={true}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

// Export the wrapped component
export const SurveyLogic = () => {
  return (
    <ReactFlowProvider>
      <SurveyLogicFlow />
    </ReactFlowProvider>
  );
};

