import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useAtom } from "jotai";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { animals, colors, uniqueNamesGenerator } from "unique-names-generator";
import { AppContext } from "../../utils/AppContext";
import { enums } from "../../utils/enums";
import { db } from "../../utils/firebaseApp";
import { CreateSurveyQuestionsState, initialSurvey } from "./atoms";
import { SurveyLogic } from "./components/logic-branching/survey-logic";
import { SurveyInformation } from "./components/survey-information";
import { SurveyQuestions } from "./components/survey-questions";
import { useSaveSurvey } from "./hooks/useSaveSurvey";
import {Stack} from "@mui/material";

export const CreateSurveyNew = () => {

  const {id} = useParams();
  const {survey, setSurvey} = useSaveSurvey();
  const [questions, setQuestions] = useAtom(CreateSurveyQuestionsState);
  const navigate = useNavigate();
  const {state: {workspaces, workspaceId}} = useContext(AppContext);
  const [tabIndex, setTabIndex] = useState(0);
  const availableViews = ["Questions", "Logic"];

  useEffect(() => {
    if (workspaces && workspaceId) {
      const currentWorkspace = workspaces.find(w => w.uid === workspaceId);
      if (currentWorkspace?.plan === enums.UNPAID) {
        navigate('/workspaces');
      }
    }
  }, [workspaces, workspaceId]);

  useEffect(() => {
    if (id) {
      const unsubscribe = onSnapshot(doc(db, "surveys", id), (doc) => {
        const newSurvey = doc.data() || {};
        if (newSurvey && newSurvey?.uid) {
          console.log("Survey exist and updated...", doc.data())
          setSurvey(newSurvey);
          console.log("Updated");
        }
      }, () => {
        console.log("Survey not exist initialize name and id")
      })
      return () => unsubscribe()
    } else {
      const newUid = crypto.randomUUID();
      const name = uniqueNamesGenerator({
        dictionaries: [colors, animals],
        style: "lowerCase",
        separator: "-",
      })
      console.log("workspaceId", workspaceId)
      setSurvey({
        ...initialSurvey,
        uid: newUid,
        surveyName: name,
        workspaceId: workspaceId
      });
      setQuestions([])
      console.log("Survey and questions initialization")
      navigate(`/create-survey/${newUid}`)
    }
  }, [id])

  useEffect(() => {
    if (survey?.uid) {
      const surveyUid = id || survey?.uid;
      const customQuery = query(collection(db, "questions"), where('surveyUid', '==', surveyUid));
      const unsubscribe = onSnapshot(customQuery, (querySnapshot) => {
        let newQuestions = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newQuestions.push(data);
        });
        setQuestions(newQuestions);
      });

      return () => unsubscribe();
    }
  }, [survey?.uid])

  return (
    <Stack direction={"column"} gap={2} maxWidth={"lg"} sx={{mx: "auto"}}>
      <SurveyInformation tabIndex={tabIndex} setTabIndex={setTabIndex} availableViews={availableViews}/>
      {tabIndex === 0 && <SurveyQuestions/>}
      {tabIndex === 1 && <SurveyLogic/>}
    </Stack>
  )
}