import {Drawer, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {AiDrawerState} from "../atoms";
import {useAtom} from "jotai";
import {IconX} from "@tabler/icons-react";
import ChatWindow from "../../survey-designer/ChatWindow";

const drawerWidth = "400px"

export const AiDrawer = () => {
  const [open, setOpen] = useAtom(AiDrawerState);

  const handleClose = () => setOpen(false);

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      disableEnforceFocus={true}
      sx={{
        position: "fixed",
        maxWidth: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          maxWidth: drawerWidth,
          width: "100%",
          boxSizing: 'border-box',
          zIndex: 4, px: 2, pb: 2,
        },
      }}
      variant="persistent"
      anchor="right"
    >
      <Stack direction={"column"} justifyContent={"space-between"} sx={{position: "relative", height: '100%'}}>
        <Toolbar />
        <IconButton sx={{position: "absolute", top: 100, right: 0}} onClick={handleClose}><IconX /></IconButton>
        <ChatWindow />
      </Stack>
    </Drawer>
  )
}