import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  IconButton,
  Paper,
  Radio,
  Stack,
  TextField,
  Typography,
  Divider
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../utils/AppContext";
import { enums } from "../../utils/enums";
export const WorkspacePlanPage = () => {
  const { id } = useParams();
  const { state: { workspaces } } = useContext(AppContext);
  const navigate = useNavigate();
  const [selectedOccurence, setSelectedOccurence] = useState('monthly');


  // Initialize states with current workspace data
  const [selectedPlanEnum, setSelectedPlanEnum] = useState(null);
  const [collaborators, setCollaborators] = useState(enums.MAX_FREE_WORKSPACE_MEMBERS[selectedPlanEnum]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);

  useEffect(() => {
    // Get current workspace from state using the URL param
    const currentWorkspace = workspaces?.find(w => w.uid === id);
    setCurrentWorkspace(currentWorkspace);
    setSelectedPlanEnum(currentWorkspace?.plan);
  }, [workspaces]);

  useEffect(() => {
    setCollaborators(enums.MAX_FREE_WORKSPACE_MEMBERS[selectedPlanEnum]);
  }, [currentWorkspace?.plan]);

  useEffect(() => {
    setCollaborators(enums.MAX_FREE_WORKSPACE_MEMBERS[selectedPlanEnum]);
  }, [selectedPlanEnum]);

  const plans = [
    {
      id: enums.UNPAID,
      name: 'Unpaid',
      priceDisplay: '',
      price: null,
      surveys: '0 surveys',
      collaborators: 0 + ' collaborators',
      disabled: false,
      features: []
    }, {
      id: enums.FREE,
      name: 'Free forever',
      priceDisplay: 'Free',
      price: null,
      surveys: enums.MAX_SURVEYS[enums.FREE] + ' surveys',
      collaborators: enums.MAX_FREE_WORKSPACE_MEMBERS[enums.FREE] + ' collaborators',
      disabled: currentWorkspace?.plan === enums.UNPAID,
      discount: '',
      features: [
        "1 free shared folder",
        "All Ella features available to Pro",
        // "Qualifying social impact and citizen science projects can receive additional access. Contact us for more information."
      ]
    },
    {
      id: enums.MONTHLY,
      name: 'Pro',
      priceDisplay: '',
      price: {
        monthly: 39,
        annual: 34
      },
      surveys: enums.MAX_SURVEYS[enums.MONTHLY] + ' surveys maximum',
      responses: enums.MAX_RESPONSES_PER_MONTH[enums.MONTHLY] + ' responses maximum',
      collaborators: enums.MAX_FREE_WORKSPACE_MEMBERS[enums.MONTHLY] + ' collaborators',
      features: [
        "100+ languages",
        "AI topic classification",
        "Real-time sharing",
        "Visualized responses"
      ]
    },
    {
      id: enums.ANNUAL,
      name: 'Advanced',
      price: {
        monthly: 84,
        annual: 74
      },
      priceDisplay: '',
      surveys: enums.MAX_SURVEYS[enums.ANNUAL] + ' surveys maximum',
      responses: enums.MAX_RESPONSES_PER_MONTH[enums.ANNUAL] + ' responses maximum',
      collaborators: enums.MAX_FREE_WORKSPACE_MEMBERS[enums.ANNUAL] + ' collaborators',
      discount: '',
      features: [
        "Everything in the Pro plan",
        "API access to responses",
        "Logic flows",
        "Custom branding"
      ]
    }
  ];

  const calculateTotal = () => {
    const selectedPlanData = plans.find(p => p.id === selectedPlanEnum);
    const additionalCollaboratorsPrice = 3
    // Calculate additional collaborators cost (if any)
    const includedCollaborators = enums.MAX_FREE_WORKSPACE_MEMBERS[selectedPlanEnum];
    const additionalCollaborators = Math.max(0, collaborators - includedCollaborators);
    const monthlyCollaboratorCost = additionalCollaborators * additionalCollaboratorsPrice;
    console.log(monthlyCollaboratorCost);
    // For annual plan, charge 12 months of collaborator costs upfront
    const collaboratorCost = selectedPlanEnum === enums.ANNUAL
      ? (monthlyCollaboratorCost * 12)
      : monthlyCollaboratorCost;
    console.log(selectedPlanData.price, collaboratorCost);
    return selectedPlanData.price + collaboratorCost;
  };

  if (!currentWorkspace) {
    return <div>Loading...</div>;
  }

  return (
    <Stack
      direction="column"
      spacing={4}
      maxWidth="lg"
      sx={{ mx: 'auto', p: 4 }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            color: 'text.primary',
            padding: 0,
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          {currentWorkspace?.name}
        </Typography>
      </Stack>

      <Typography variant="body1" color="text.secondary">
        Workspaces are shared folders where you can collaborate fully with users outside your Team.
        All responses to surveys within workspaces count towards your account's monthly survey response limit.
      </Typography>

      <Stack direction="row" spacing={2}>
        {plans.map((plan) => (
          <Paper
            key={plan.id}
            elevation={0}
            sx={{
              p: 3,
              flex: 1,
              border: '1px solid',
              borderColor: plan.disabled ? 'divider' : (selectedPlanEnum === plan.id ? 'primary.main' : 'divider'),
              borderRadius: 2,
              cursor: plan.disabled ? 'not-allowed' : 'pointer',
              bgcolor: plan.disabled ? 'action.disabledBackground' :
                (selectedPlanEnum === plan.id ? 'primary.50' : 'background.paper'),
              position: 'relative',
              opacity: plan.disabled ? 0.6 : 1,
              pointerEvents: plan.disabled ? 'none' : 'auto'
            }}
            onClick={() => !plan.disabled && setSelectedPlanEnum(plan.id)}
          >
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Radio
                  checked={selectedPlanEnum === plan.id}
                  onChange={() => setSelectedPlanEnum(plan.id)}
                  disabled={plan.disabled}
                />
                <Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      color: plan.disabled ? 'text.disabled' : 'text.primary'
                    }}
                  >
                    {plan.name}
                  </Typography>
                  {currentWorkspace?.plan === plan.id && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: plan.disabled ? 'text.disabled' : 'primary.main',
                        fontWeight: 500
                      }}
                    >
                      Current plan
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack spacing={0}>
                <Typography
                  variant="h5"
                  sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary', marginBottom: 0, visibility: plan.price ? 'visible' : 'hidden' }}
                >
                  {"$" + plan?.price?.monthly + "/mo"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary', fontSize: 12, visibility: plan.price ? 'visible' : 'hidden' }}
                >
                  {"$" + plan?.price?.annual + "/mo (billed annually)"}
                </Typography>
              </Stack>

              <Stack spacing={1}>
                <Typography
                  variant="body1"
                  sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary' }}
                >
                  {plan.surveys}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary' }}
                >
                  {plan.collaborators}
                </Typography>
              </Stack>

              {plan.features.length > 0 && <Stack spacing={1}>
                <Divider sx={{ borderColor: 'divider', my: 2 }} />
                <Typography variant="body1" sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary', mt: 2, mb: 1, fontWeight: 500 }}>
                  Features:
                </Typography>
              </Stack>}

              {plan.features.length > 0 && <Stack spacing={1}>
                {plan.features.map((feature, index) => (
                  <Typography
                    variant="body1"
                    sx={{ color: plan.disabled ? 'text.disabled' : 'text.primary' }}
                  >
                    - {feature}
                  </Typography>
                ))}
              </Stack>}

              {plan.discount && (
                <Typography
                  variant="caption"
                  sx={{
                    bgcolor: plan.disabled ? 'action.disabledBackground' : 'primary.main',
                    color: plan.disabled ? 'text.disabled' : 'white',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    alignSelf: 'flex-start'
                  }}
                >
                  {plan.discount}
                </Typography>
              )}
            </Stack>
          </Paper>
        ))}
      </Stack>

      <Stack spacing={2}>
        <Typography variant="h6">
          Select the number of collaborators within this workspace
        </Typography>
        <Typography variant="body2" color="text.secondary">
          If you require more collaborators, select an option below.
          Each additional collaborator will add $3/mo.
          You can change this number at any time and it will be reflected in your next month's bill.
        </Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              width: 'fit-content'
            }}
          >
            <IconButton
              onClick={() => setCollaborators(prev => Math.max(3, prev - 1))}
              size="small"
            >
              <RemoveIcon />
            </IconButton>
            <TextField
              value={collaborators}
              size="small"
              sx={{
                width: 60,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                }
              }}
              inputProps={{
                style: { textAlign: 'center' },
                min: 3
              }}
            />
            <IconButton
              onClick={() => setCollaborators(prev => prev + 1)}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Stack>
          <Typography>Collaborators</Typography>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          pt: 3
        }}
      >
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="h6">Total</Typography>
          <Typography variant="h5">
            ${calculateTotal()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            due today
          </Typography>
        </Stack>

        <Button
          variant="contained"
          color="primary"
          size="large"
          // disabled={selectedPlanEnum === enums.UNPAID || selectedPlanEnum === enums.FREE}
          disabled={true}
          onClick={() => {/* Handle payment */ }}
        >
          {/* Proceed to payment details */}
          Payment will be available soon
        </Button>
      </Stack>
    </Stack>
  );
}; 