import {Typography, Box, Stack} from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import {useAtomValue} from "jotai";
import {
  CreateSurveyQuestionsState,
  SelectedLanguageState,
} from "../atoms";
import { QuestionItem } from "./question-item";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {toast} from "react-toastify";
import {CreateQuestionButton} from "./create-question-button";

const groupByLanguage = (questions) => {
  return questions.reduce((groups, question) => {
    const key = question.languageObject.BCP47;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(question);
    return groups;
  }, {});
};

export const SurveyQuestions = () => {
  const questions = useAtomValue(CreateSurveyQuestionsState);
  const selectedLanguage = useAtomValue(SelectedLanguageState);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const bottomRef = useRef();

  useEffect(() => {
    if (questions && selectedLanguage) {
      setFiltered(questions?.filter(item => item?.languageObject?.BCP47 === selectedLanguage?.BCP47)?.sort((a, b) => +a.orderNumber - +b?.orderNumber) || [])
    }
  }, [selectedLanguage, questions])

  useEffect(() => {
    if (bottomRef && bottomRef?.current) {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  }, [filtered?.length])

  // Group questions by language

  // Handle drag end event
  const handleDragEnd = async (result) => {
    if (!result.destination || loading) return;

    const { source, destination } = result;

    if (source.index === destination.index) return;

    const grouped = groupByLanguage([...questions]);
    const reordered = [];
    for (const [key, questionGrouped] of Object.entries(grouped) || []) {
      const reorderedItems = Array.from(questionGrouped?.sort((a, b) => +a?.orderNumber - +b?.orderNumber));
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      reordered.unshift(reorderedItems?.map((i , index) => ({...i, orderNumber: index})));
    }
    const newQuestions = reordered?.flat() || [];
    const batch = writeBatch(db);
    
    for (const updateQuestion of newQuestions) {
      if (updateQuestion && updateQuestion?.uid) {
        // Find the next question in sequence
        const nextQuestion = newQuestions.find(q => q.orderNumber === updateQuestion.orderNumber + 1);
        
        batch.update(doc(db, "questions", updateQuestion.uid), {
          orderNumber: updateQuestion.orderNumber,
          allOtherCasesQuestion: nextQuestion?.orderNumber || null
        });
      }
    }

    try {
      setLoading(true);
      await batch.commit();
    } catch (error) {
      toast.error(error?.message || "Failed to reorder");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd} isDropDisabled={loading}>
        <Droppable droppableId="questionsList">
          {(provided) => (
            <Stack direction={"column"} gap={2} sx={{position: "relative", minHeight: "80%"}} {...provided.droppableProps} ref={provided.innerRef}>
              {filtered?.map((question, index) => (
                <Draggable key={question?.uid} draggableId={question?.uid} index={index}>
                  {(provided) => (
                    <Stack
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      direction={"row"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Box {...provided.dragHandleProps}>
                        <DragIndicator sx={{ cursor: loading ? "wait" : "grab" }} />
                      </Box>
                      <QuestionItem question={question} />
                    </Stack>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <CreateQuestionButton/>
              <div ref={bottomRef} style={{paddingBottom: "150px"}} />
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      {/*<CreateQuestionButton/>*/}
    </>
  );
};
