import {useAtomValue} from "jotai";
import {AnswerAtom} from "../atoms";
import React from "react";
import {Stack, Typography} from "@mui/material";
import {enums} from "../../../utils/enums";
import {TextQuestion} from "./TextQuestion";
import {VoiceQuestion} from "./VoiceQuestion";
import {MultiChoiceQuestion} from "./MultiChoiceQuestion";
import {CheckBoxQuestion} from "./CheckBoxQuestion";
import {SliderQuestion} from "./SliderQuestion";
import {DropdownQuestion} from "./DropdownQuestion";
import {MapInputQuestion} from "./MapInputQuestion";
import {SectionQuestion} from "./SectionQuestion";
import {GeoLocationQuestion} from "./GeoLocationQuestion";
import {ImageQuestion} from "./ImageQuestion";
import {ImageGalleryQuestion} from "./ImageGalleryQuestion";
import QuestionTitle from "./QuestionTitle";

export const Question = () => {
  const answer = useAtomValue(AnswerAtom);

  return (
    <Stack direction={"column"} gap={2}
           sx={{maxHeight: "100%", flex: 1, overflowY: "auto", overflowX: "hidden", p: 2, position: "relative"}}>
      <Stack direction={"column"} gap={0}>
        <QuestionTitle title={answer?.question ? <>{answer?.question} {answer?.required ?
          <span style={{color: "red"}}>*</span> : null}</> : "-"}/>
        {
          answer?.subTitle ?
            <Typography align={"left"} fontWeight={400} fontSize={"16px"} lineHeight={"1.3"} color={"#888888"}>
              {answer?.subTitle}
            </Typography> :
            <Typography fontSize={"0.75rem"} fontWeight={400} color={"text.secondary"} fontStyle="italic">
              {answer?.description || ""}
            </Typography>
        }
      </Stack>
      {
        {
          [enums.TEXT]: <TextQuestion/>,
          [enums.VOICE]: <VoiceQuestion/>,
          [enums.MULTICHOICE]: <MultiChoiceQuestion/>,
          [enums.CHECKBOXES]: <CheckBoxQuestion/>,
          [enums.SLIDER]: <SliderQuestion/>,
          [enums.DROPDOWN]: <DropdownQuestion/>,
          [enums.MAPINPUT]: <MapInputQuestion/>,
          [enums.SECTION]: <SectionQuestion/>,
          [enums.LOCATE_USER]: <GeoLocationQuestion/>,
          [enums.IMAGE]: <ImageQuestion/>,
          [enums.IMAGE_GALLERY]: <ImageGalleryQuestion/>,
          [undefined]: <Typography>Question type not found...</Typography>,
          [null]: <Typography>Question type not found...</Typography>
        }[answer?.type]
      }
    </Stack>
  )
}
