import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { confirm } from "react-confirm-box";
import { useNavigate } from "react-router-dom";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { AppContext } from "../../../utils/AppContext";
import { enums } from "../../../utils/enums";
import { auth, db } from "../../../utils/firebaseApp";
import { createNewWorkspace } from "../../../utils/sharedFunctions";
import { MemberList } from "./MemberList";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { useSaveWorkspace } from "../hooks/useSaveWorkspace";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../utils/firebaseApp";

export const WorkspaceInformation = ({onDelete, workspace, setWorkspace }) => {
    const navigate = useNavigate();
    const { state: { workspaces }, dispatch } = useContext(AppContext);
    // const [workspace, setWorkspace] = useState(/* initial workspace state */);
    const { saveWorkspace } = useSaveWorkspace();

    const handleChange = (field) => (event) => {
        const updatedWorkspace = {
            ...workspace,
            [field]: event.target.value
        };
        setWorkspace(updatedWorkspace);
    };

    const handleBlur = (field) => async () => {
        try {
            await saveWorkspace(workspace);
            toast.success(`${field} updated`);
        } catch (error) {
            console.error(`Error saving ${field}:`, error);
            toast.error(`Failed to save ${field}`);
        }
    };

    const handleUpdateMember = async (memberId, newRole) => {
        const updatedMemberDetails = workspace.memberDetails.map(member =>
            member.uid === memberId ? { ...member, role: newRole } : member
        );
        
        const updatedWorkspace = {
            ...workspace,
            memberDetails: updatedMemberDetails,
        };
        setWorkspace(updatedWorkspace);
        await saveWorkspace(updatedWorkspace);
    }

    const handleDeleteWorkspace = async () => {
        try {
            const response = await confirm(
                {
                    title: `Are you sure you want to delete this workspace?`,
                    subtitle: `This action will delete all surveys, answers, and tokens in this workspace.`,
                },
                confirmDialogOptions,
            );
            if (response === false) return;

            onDelete(workspace.uid);
            toast.success("Workspace deleted");
        } catch (error) {
            console.error("Error deleting workspace:", error);
        }
    };

    const handleRemoveMember = async (memberId) => {
        const updatedMembers = workspace.members.filter(member =>
            member.uid !== memberId
        );
        const updatedMemberDetails = workspace.memberDetails.filter(member =>
            member.uid !== memberId
        );
        const updatedWorkspace = {
            ...workspace,
            members: updatedMembers,
            memberDetails: updatedMemberDetails
        };
        setWorkspace(updatedWorkspace);
        await saveWorkspace(updatedWorkspace);
    }


    const handleAddMember = async (newMemberEmail, role) => {
        try {
            toast.info("Adding member to workspace...");
            const createMemberFunction = httpsCallable(functions, 'createWorkspaceMember');
            const result = await createMemberFunction({ 
                email: newMemberEmail,
                workspaceUid: workspace.uid,
                role: role
            });

            if (!result.data.userId) {
                throw new Error('No user ID returned from function');
            }

            const updatedMembers = [
                ...workspace.memberDetails,
                { uid: result.data.userId, role }
            ];

            const updatedMemberArray = [
                ...workspace.members,
                result.data.userId
            ];
            
            const updatedWorkspace = {
                ...workspace,
                memberDetails: updatedMembers,
                members: updatedMemberArray
            };
            
            setWorkspace(updatedWorkspace);
            await saveWorkspace(updatedWorkspace);

            toast.success("Members updated workspace");

        } catch (error) {
            console.error("Error adding member:", error);
            toast.error("Error adding member to workspace");
        }
    };

    return (
        <Stack direction="column" spacing={5}>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <IconButton
                    onClick={() => navigate('/workspaces')}
                    sx={{
                        color: 'text.primary',
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        }
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>
                    Workspaces
                </Typography>
            </Stack>

            <div>
                <TextField
                    value={workspace?.name}
                    size="large"
                    sx={{
                        color: 'text.primary',
                        width: '500px',
                        '& .MuiInputBase-input': {
                            fontSize: 20
                        }
                    }}
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                    fullWidth
                    variant="standard"
                />
            </div>

            <div>

                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Usage</Typography>
                <Typography variant="body1" >
                    {workspace?.members.length} / {enums.MAX_FREE_WORKSPACE_MEMBERS[workspace.plan]} collaborators
                </Typography>
            </div>
            <MemberList
                workspace={workspace}
                members={workspace.memberDetails}
                onAddMember={handleAddMember}
                onUpdateMember={handleUpdateMember}
                onRemoveMember={handleRemoveMember}
            />
            <div>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Plan</Typography>
                <Typography variant="body1" sx={{ color: 'text.primary' }}>This workspace is on the {workspace.plan} plan.
                    {workspace.plan === enums.FREE ? 'You can upgrade to a paid plan to add more collaborators.' : ''}</Typography>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2, width: '200px' }}
                    onClick={() => navigate(`/workspace-plan/${workspace.uid}`)}
                >
                    Change plan
                </Button>
            </div>

            <div>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>Workspace Management</Typography>
                <Button variant="contained" size="small" sx={{ mt: 2, width: '200px' }} color="error" disabled={workspace.plan === enums.FREE} onClick={handleDeleteWorkspace}>Delete Workspace</Button>
                {workspace.plan === enums.FREE && <Typography variant="body2" sx={{ color: 'text.error' }}>
                    You cannot delete a free workspace.
                </Typography>}
            </div>
        </Stack>
    );
};

export const generateSecurePassword = () => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*';

    const allChars = lowercase + uppercase + numbers + symbols;

    // Ensure at least one of each type
    let password =
        lowercase[Math.floor(Math.random() * lowercase.length)] +
        uppercase[Math.floor(Math.random() * uppercase.length)] +
        numbers[Math.floor(Math.random() * numbers.length)] +
        symbols[Math.floor(Math.random() * symbols.length)];

    // Add 8 more random characters
    for (let i = 0; i < 8; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the password
    return password.split('').sort(() => Math.random() - 0.5).join('');
}; 